var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.loadViewportedHotel),expression:"loadViewportedHotel"}],staticClass:"hotels-group",class:{ 'skeleton-container': _vm.isLoading }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"skeleton hotels-group__skeleton"}),_c('div',{staticClass:"hotels-group__hotel-info"},[_c('div',{staticClass:"hotels-group__hotel-info__left"},[_c('div',{staticClass:"hotels-group__hotel-info__left__name"},[_c('h3',{on:{"click":_vm.goToHotel}},[_c('span',{class:{ skeleton: _vm.isLoading },domProps:{"innerHTML":_vm._s(_vm.hotel.hotelName)}})]),_c('router-link',{attrs:{"to":_vm.targetRoute}})],1)])]),_c('div',{staticClass:"hotels-group__card-sets"},[(!_vm.isLoading)?_vm._l((_vm.providers),function(provider){return _c('div',{key:provider,staticClass:"hotels-group__card-sets__provider"},[_c('div',{staticClass:"hotels-group__card-sets__item",on:{"mouseenter":e => _vm.handleHover(e, {
                        provider,
                        compsetId: _vm.mainCompset?.id,
                        document: _vm.hotel.compsetMain,
                        hotelId: _vm.hotel.hotelId,
                    }),"mouseleave":e => _vm.handleHover(e, null)}},[(_vm.mainCompset && !_vm.isLoading)?_c('RankingChainRange',{attrs:{"provider":provider,"compset-id":_vm.mainCompset.id,"compset-main":_vm.hotel.compsetMain,"hotelId":_vm.hotel.hotelId}}):_vm._e()],1)])}):_vm._l((3),function(n){return _c('div',{key:n + 'ranges',staticClass:"hotels-group__card-sets__provider"},[_vm._m(0,true)])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"hotels-group__card-sets__item"},[_c('div',{staticClass:"range-skeleton skeleton"})])
}]

export { render, staticRenderFns }