
import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RankingChainHeader from '@/modules/cluster/components/ranking/ranking-chain-header.vue';
import RankingChainList from '@/modules/cluster/components/ranking/ranking-chain-list.vue';
import ClusterRankingService, { ClusterRankingServiceS } from '@/modules/cluster/cluster-ranking.service';
import RankingClusterOtelMixin from '@/modules/open-telemetry/mixins/ranking/ranking-cluster-otel.mixin';
import RankingClusterActions from '../components/ranking/ranking-cluster-actions.vue';

@Component({
    components: {
        PageWrapper,
        RankingChainList,
        RankingChainHeader,
        RankingClusterActions,
    },
})
export default class RankingClusterPage extends Mixins(RankingClusterOtelMixin) {
    @Inject(ClusterRankingServiceS) private clusterRankingService!: ClusterRankingService;

    beforeMount() {
        this.clusterService.hotels = null;
        this.clusterService.saveCurrentProvider(this.clusterRankingService.providers[0]);
    }

    destroyed() {
        this.clusterService.resetLoading();
    }
}
