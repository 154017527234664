
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import RankingHotelCompsetGroup from '@/modules/cluster/components/ranking/ranking-hotel-compset-group.vue';
import ClusterRankingService, { ClusterRankingServiceS } from '@/modules/cluster/cluster-ranking.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import CustomTooltipV2, { TOOLTIP_DIRECTION } from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import Skeleton from '../_common/hotels-list/skeleton.vue';
import RankingChainProvider from './ranking-chain-provider.vue';
import ClusterPagination from '../_common/cluster-pagination.vue';

type HintData = {
    provider: string;
    compsetId: string;
    hotelId: number;
    document: Object;
};

@Component({
    components: {
        RankingHotelCompsetGroup,
        ProviderCard,
        Skeleton,
        ClusterPagination,
        CustomTooltipV2,
        RankingChainProvider,
    },
})
export default class RankingChainList extends Vue {
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(ClusterRankingServiceS) private clusterRankingService!: ClusterRankingService;
    @Inject(HelperServiceS) private helperService!: HelperService;

    isFirstLoading = true;
    height = '0px';
    hint: {
        rect: DOMRect | null;
        data: HintData | null;
    } = {
        rect: null,
        data: null,
    };
    tooltipDirection = TOOLTIP_DIRECTION.LEFT_RIGHT;

    mounted() {
        const el = this.$refs.groups as HTMLElement;
        const elY = el.getBoundingClientRect().y;
        const wHeight = window.innerHeight;
        this.height = `${wHeight - elY}px`;
    }

    get providers() {
        return this.clusterRankingService.providers;
    }

    get hotels() {
        if (this.clusterRankingService.clusterRankingsData) {
            this.clusterRankingService.clusterRankingsData
                .sort((a, b) => a.hotelName.localeCompare(b.hotelName));
        }
        return this.clusterRankingService.clusterRankingsData;
    }

    get isLoading() {
        return this.clusterService.isLoading;
    }

    get isSearchQuery() {
        return this.clusterService.clusterHotelSearchQuery;
    }

    getLogo(provider: string) {
        return this.helperService.providerLogo(provider);
    }

    setTooltip(data: { position: DOMRect, payload: HintData } | null) {
        if (!data?.payload) {
            this.hint.rect = null;
            return;
        }

        this.hint.rect = data.position;
        this.hint.data = data.payload;
    }
}
